<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
  <div style="float: right; margin-bottom: 3px" v-if="!showFilter && !__byItem"><b-button size="lg" variant=""  @click="showFilter = true"> Filtre</b-button></div>
    <div style="clear: both"></div>

    <div class="row serachPanel" v-if="showCmds === -1 && __byClient === false && showFilter" v-on:dblclick="showFilter = false">
      <div class="col-md-6">
        <span class="title">Recherche<br></span>
        <base-input
          placeholder="nom client"
          v-model="filterContainer.keySearch"
          type="string"
        ></base-input>
      </div>
     
      <div class="col-md-3">
        <selector
          _id="6674b8bcbff6bf20c59aa5e1"
          _customTitle="Consommés"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setConsumedData"
          :_parentItem="filterContainer"
          _warpper="filter_consumed"
        ></selector>
      </div>
      <div class="col-md-3">
        <selector
          _id="6674b8bcbff6bf20c59aa5e1"
          _customTitle="Validés"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setConfirmedData"
          :_parentItem="filterContainer"
          _warpper="filter_confirmed"
        ></selector>
      </div>
     
    </div>

    <div class="row">
      <div
        
        v-for="(item, index) in filtredList()"
        :key="index"
        :class="styleSublisteMode + isCurrent(index === showList, item.confirmed, item.consumed, item.cmdId === showCmds)"
        :style=""
        v-if="showCmds === -1 || (showCmds === item.cmdId)"
      >
        <div class="row">

          


          <div class="col-md-3" v-if="__byClient === false">
            {{ item.clientName }}
            <br />
            <em style="font-size: 11px">{{ formatDate(item.createdAt) }}</em>
          </div>

          <div :class="(!__byClient) ? 'col-md-2'  : 'col-md-3' " >
            <div v-if="item.confirmed"><b-icon icon="file-check-fill" style="color:green"></b-icon> Confirmé</div>
            <div v-if="!item.confirmed"><b-icon icon="file-check" style="color:red"></b-icon> Non confirmé</div>
            <em  v-if="item.confirmed" style="font-size: 11px">{{ formatDate(item.confirmDate) }}</em>
          </div>

          <div class="col-md-3" >
            <div v-if="item.consumed"><b-icon icon="basket-fill" style="color:green"></b-icon> Utilisé</div>
            <div v-if="!item.consumed"><b-icon icon="basket-fill" style="color:orange"></b-icon> Non utilisé</div>
            <em  v-if="item.consumed" style="font-size: 11px">{{ formatDate(item.consumedDate) }}</em>
          </div>

          <div :class="(!__byClient) ? 'col-md-1'  : 'col-md-2' " >
            <b-icon icon="recycle" style="color:orange"></b-icon> {{ item.nbrpts }}<br>
            <b-icon icon="currency-dollar" style="color:cyan"></b-icon> {{ item.values }}
          </div>

          <div :class="(!__byClient) ? 'col-md-1'  : 'col-md-2' ">
            <b-icon icon="caret-up-fill" style="color:green"></b-icon> {{ item.currentSolde }}<br>
            <b-icon icon="caret-down-fill" style="color:red"></b-icon> {{ item.currentSolde - item.nbrpts }}
          </div>
          

          <div :class="(!__byClient) ? 'col-md-2'  : 'col-md-2' ">
            <div class="menu">
              <ico-btn size="p" type="toggleOn" custom="orange" icone="file-check" v-if=" item.confirmed == true && item.consumed === false && !__byClient" :cb="()=>{dispatchDesactive(item._id)}"></ico-btn>
              <ico-btn size="p" type="toggleOn" custom="green" icone="file-check" v-if=" item.confirmed == false && item.consumed === false && !__byClient" :cb="()=>{dispatchActive(item._id)}"></ico-btn>

              <ico-btn
                v-if="!item.consumed"
                size="p"
                type="warning"
                icone="trash"
                :cb="
                  () => {
                    dispatchDelete(item._id);
                  }
                "
              ></ico-btn>

              <ico-btn
                v-if="!item.consumed"
                size="p"
                type="simple"
                icone="gear-fill"
                :cb="
                  () => {
                    dispatchEdit(item._id);
                  }
                "
              ></ico-btn>

              <ico-btn
              size="p"
              v-if="item.consumed"
              type="simple"
              icone="list-check"
              :cb="
                () => {
                  showCmds = (showCmds === -1) ? item.cmdId : -1;
                }
              "
            ></ico-btn>
            </div>
          </div>


        </div>

        
      </div>

      <cmds v-if="showCmds !== -1" :byItem="showCmds" style="width:100%; margin-left:0px"></cmds>
    </div>
  </b-overlay>
</template>
<script>
import cmds from '@/pages/cmds/index';
export default {
  components: {cmds},
  props: {
    __byClient: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      filterContainer: {  keySearch: "", filter_confirmed: "", filter_consumed: "" },
      structure: { consumed: [], confirmed: [] },
      fullList: [],
      showList: -1,
      showFilter: true,
      showCmds: -1,
    };
  },
  methods: {
    formatDate(d) {
      var dt = new Date(d);
      return (
        "Le " +
        dt.getDate() +
        "/" +
        dt.getMonth() +
        "/" +
        dt.getFullYear() +
        " a " +
        dt.getHours() +
        ":" +
        dt.getMinutes()
      );
    },


    filtredList() {
      var ret = this.fullList;
      if (this.filterContainer.filter_confirmed)
        ret = ret.filter((e) => e.confirmed === (this.filterContainer.filter_confirmed === "Oui"));

      if (this.filterContainer.filter_consumed)
        ret = ret.filter((e) => e.consumed === (this.filterContainer.filter_consumed === "Oui"));

      if (this.filterContainer.keySearch)
        ret = ret.filter((e) =>
          e.clientName?.includes(this.filterContainer.keySearch)
        );
      return ret;
    },

    setConfirmedData(list) {
      this.structure.confirmed = list;
    },

    setConsumedData(list) {
      this.structure.consumed = list;
    },

    isCurrent(current, confirmed, consumed, showCmd) {

      var _showCmd = showCmd ? ' withSub' : '';

      var con = confirmed ? ' confirmed' : ' unconfirmed';
      con += consumed ? ' consumed' : ' unconsumed';
      con += _showCmd;
      if (this.showList !== -1) return con+(current ? "  actif" : " flowted");
      return con
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    formatList() {
      this.fullList = this.fullList.map((e) => {
        return {
          ...e,
        };
      });
    },


    showExtraFilter(v = '') {
      return true
    },

    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx,true);
    },

    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx,false);
    },
  },

  computed: {
    styleSublisteMode() {
      return this.__editMode !== true
        ? "listeMode col-md-12"
        : "editMode col-md-12";
    },
  },

  watch: {
    __list: {
      deep: true,
      handler(newValue, oldValue) {
        this.fullList = this.$props.__list;
        this.formatList();
      },
    },
    structure: {
      deep: true,
      handler(newValue, oldValue) {
        console.log("newValue");
        this.formatList();
      },
    },
  },
  
  mounted() {
    setTimeout(() => {
      this.fullList = this.$props.__list;
        this.formatList();
    }, 500);
  },
};
</script>
<style scoped>
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.listeMode {
  padding: 3%;
  margin-bottom: 20px;
  border-radius: 30px;
  min-height: 50px;
  background-color: #fff;
}
.listeMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.pic {
  width: 17%;
  margin-right: 3%;
  float: left;
}
.pic img {
  border-radius: 50%;
}
.preview {
  float: left;
  font-weight: bolder;
}

.preview em {
  font-weight: lighter;
  font-size: 12px;
}

.flowted {
  filter: blur(3.5px);
}

.actif {
  filter: none;
  background-color: #131016;
}

.shop {
  margin-right: 5px;
  color: #2bffc6;
}
.service {
  margin-right: 5px;
  color: #ff8d72;
}
.lubrifiant {
  margin-right: 5px;
  color: #ffd600;
}

.carburant {
  margin-right: 5px;
  color: #b1cf0c;
}

.serachPanel {
  padding: 3%;
  background-color: #171f3c;
  border-radius: 30px;
  margin:20px 0;
}
.confirmed {
  border-left: solid 3px green;
}
.unconfirmed {
  border-left: solid 3px red;
}
.consumed {
  border-right: solid 3px green;
}
.unconsumed {
  border-right: solid 3px red;
}

.withSub {
  background-color: #cafdb8;
  color: black !important;
}
.withSub .menu {
  background-color: #cafdb8 !important;
}
</style>
