<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="contentAgent">
    <b-overlay
      :show="isOnLoad()"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
      style="float: left; width: 100%"
    >
    <fade-transition :duration="100" mode="out-in">
     
      <div class="row">
        <div class="col-md-12" style="margin-top:-55px"><h3 style="margin-bottom:0px">Bonjour {{ client.name }} </h3></div>
        <div class="col-md-4">
          <h4>* Mon compte</h4>
          <div class="qrCodePanel">
            <qrcode-vue size="150" :value="this.__.getAppShopPath()+this.client._id" level="H" style="float: left; max-width: 160px" />
          </div>
          <div class="infoPanel" style="float: left; margin-left: 10px" > 
            <b>Points collectées  {{updateDate}}</b><br>
            <h1 style="margin-bottom: 0px">Solde : {{ this.client.solde }} point(s)</h1>
            <h4 style="margin-bottom: 0px">Points cumulées : {{ this.client.solde + this.consumedPoints }} point(s)</h4>
            <h4 style="margin-bottom: 0px">Points convertis : {{ this.consumedPoints }} point(s)</h4>
          </div>
          <div style="clear:both"></div>

        <div class="bonsPanel"><bons v-if="client.pid!==''" style="opacity:1" @set-consumed="onSetConsumedEvent" :byClient="client._id" :embeded=true useTitle="Convertir mes points"></bons></div>
        </div>
        
        <div class="col-md-6"><cmds v-if="client.pid!==''" style="opacity:1" :byClient="client._id" useTitle="* Mes achats"></cmds></div>
        <div class="col-md-2 carsPanel"><cars v-if="client.pid!==''" style="opacity:1" :clientID="client._id" :embeded=true :modeClient=true useTitle=" * Mes voitures"></cars></div>
      </div>
      
    </fade-transition>
  </b-overlay>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import cmds from '@/pages/cmds/index.vue';
import cars from '@/pages/cars/index.vue';
import bons from '@/pages/bons/index.vue';
import QrcodeVue from 'qrcode.vue'
import Vue from "vue";
export default {
  components: {
    FadeTransition,
    cmds,
    bons,
    cars,
    QrcodeVue
  },
  data() {
    return {
      client: {pid:''},
      station: '',
      onGoingReq: [],
      consumedPoints: 0,
      updateDate : '',
      intervalID: null
    };
  },
  methods: {
    onSetConsumedEvent(v) {
      this.consumedPoints = v
    },
    formatDate(d) {
      var dt = new Date(d);
      return (
        "Le " +
        dt.getDate() +
        "/" +
        dt.getMonth() +
        "/" +
        dt.getFullYear() +
        " a " +
        dt.getHours() +
        ":" +
        dt.getMinutes()
      );
    },

    isOnLoad() {
      this.onGoingReq.length>0
    },

    loadStation() {
      let $onNext = (response) => {
        this.station = response.data[0].name;
        Vue.$cookies.set('station',response.data[0]._id);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/opts/'+this.agent.stationID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData() {
      const client = Vue.$cookies.get("user");
      console.log(client)
      let $onNext = (response) => {
        this.updateDate = this.formatDate(new Date())
        this.client = response.data[0];
        Vue.$cookies.set('client',response.data[0]);
        if(this.station === '')
          this.loadStation()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/clients/'+client.pid,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {
    this.intervalID = setInterval(() => this.loadData(), 20000);
    this.loadData()
  },
};
</script>
<style scoped>
 .contentAgent {
  padding: 70px 50px 0px 50px;
  min-height: calc(100vh - 70px);
 }
 .carsPanel {
  padding: 4% !important;
  border-radius: 25px
 }
 .bonsPanel {
    padding: 4% !important;
    background-color: #1b1b29;
    border-radius: 30px;
    float: left;
    margin: 15px 10px;
 }
 .contentAgent h4 {
    color: #f32524;
}
</style>
