<template>
  <b-overlay
    :show="isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
  >
    <div style="width: 100%;" >
      <div class="row" style="margin-left: 0px;">
        <div class="col-md-9" style="font-size: 28px;">./{{ folderPayload?.folderClientInfo?.folderName }}</div>
        <div class="col-md-3"  v-if="!showUploader && (folderPayload?.folderClientInfo?.maxFiles === 0 || folderPayload?.folderClientInfo?.maxFiles > folderContent.length)">
          <b-button variant="outline-primary" @click="showUploaderAction()">
            <b-icon icon="clipboard-plus"></b-icon>
            Uploader
          </b-button>
        </div>
        <div class="col-md-6" v-if="folderPayload?.folderClientInfo?.maxSize > 0">
          Espace disque
          <b-progress :max="folderPayload.folderClientInfo.maxSize" height="1rem">
            <b-progress-bar :value="folderPayload.folderClientInfo.consumedSize">
              <span><strong>{{ folderPayload.folderClientInfo.folderSize }}</strong></span>
            </b-progress-bar>
          </b-progress>

        </div>
        <div class="col-md-6" v-if="folderPayload?.folderClientInfo?.maxFiles > 0">
          Nombre de fichiers
          <b-progress :max="folderPayload.folderClientInfo.maxFiles" height="1rem">
            <b-progress-bar :value="folderContent.length">
              <span><strong>{{ folderContent.length }} - {{ folderPayload?.folderClientInfo?.maxFiles }}</strong></span>
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
      

     
      <Uploader :payload="uploadPayload" v-if="showUploader"></Uploader>
    </div>
    <div>
      <List
        :__list="folderContent"
        :__payload="folderPayload"
      ></List>
    </div>
</b-overlay>
</template>
<script>
import Uploader from './Uploader';
import List from './List';
export default {
  name: "galerie-manager",
  components: {
    Uploader,
    List,
  },
  props: {
    __payload: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isOnLoad: false,
      uploadPayload: {},
      folderPayload: {},
      showUploader: false,
      folderContent: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-4' : 'col-md-12';
    },
  },
  methods: {
    getClientFolder() {
      let $onNext = (response) => {
        if(response.data.length>0) {
          this.folderPayload = this.$props.__payload;
          this.uploadPayload = this.$props.__payload;
          this.folderPayload.folderClientInfo = response.data[0];
          this.uploadPayload.folderClientInfo = response.data[0];
          this.uploadPayload.maxUploadCount = 0;
          this.loadFolder()
        }
      };

      let $onError = (error) => {
        this.isOnLoad = false;
      };

      let $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: 'GET',
        route: '/folders/all/'+ this.$props.__payload.clientID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    loadFolder() {
      this.isOnLoad = true;
      
      this.folderPayload.onSelect = (attr) => this.onUploadOK(attr);
      this.folderPayload.onDelete = (url, idx) => this.deleteFile(url, idx);
      this.__.loadFolderContent({
          onNext: (data)=>{
            this.folderContent = [].concat(data.data);
            this.uploadPayload.maxUploadCount = this.folderPayload?.folderClientInfo?.maxFiles == 0 ? 10 : (this.folderPayload.folderClientInfo.maxFiles - data.data.length);
            this.isOnLoad = false;
          },
          onComplete: (data) => {},
          onError: () => {
            this.isOnLoad = false;
          },
          folder: this.folderPayload.folderClientInfo.folderName,
        })
    },
    deleteFile(url) {
      let $onNext = (response) => {
        this.isOnLoad = false;
        if(response.data.length>0) {
          this.getClientFolder()
        }
      };
    
      let $onError = (error) => {
        this.isOnLoad = false;
      };

      let $onComplete = () => {
        this.isOnLoad = false;
      };
      
      this.isOnLoad = true;
      this.__.httpAction({
        methode: 'DELETE',
        route: '/folders/files/'+ url.split("/").join("@"),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    showUploaderAction() {
      
      this.showUploader = true;
      this.uploadPayload.onUploadSuccess = () => {this.showUploader = false; this.getClientFolder()};
      this.uploadPayload.onUploadFail = () => {this.showUploader = false; this.getClientFolder()};
      this.uploadPayload.onMaxReached = () => {
        this.$bvModal.msgBoxConfirm(`volume maximale atteint, echec d'envoi de certains fichiers`, {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'OK',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.showUploader = false;
          }
        })
      };
      
    },
  },
  watch: {  
    __payload: function () {
      this.loadData();
    },
  },
  mounted() {
    this.getClientFolder()
  },
};
</script>
<style></style>
