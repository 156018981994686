<template>
  <div class="row" style="margin-left: 0px; margin-right:0px">
    <div class="col-md-6 display_user_logo">
      <b-overlay
      :show="inLoad"
      variant="white"
      rounded
      opacity="0.20"
      spinner-variant="primary"
      spinner-big
    >
    <img src="/img/logo.5fab4c63.png" alt="">
    <card type="user">
    
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="#">
        <img src="/img/logo.b16a3511.png" alt="">
        <h2 class="title" style="margin-top:40px; margin-bottom: 0px;">Authentification</h2>
        <h5 class="title">Veuillez remplir ces champs</h5>
      </a>
    </div>
    <p>

    <div class="row" >
    <div class="col-md-6">
      <b-input-group size="m" class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="person-fill"></b-icon>
      </b-input-group-prepend>
      <b-form-input type="text" v-model="loginFiled" placeholder="Login | email"></b-form-input>
    </b-input-group>
    </div>

    <div class="col-md-6">
    <b-input-group size="m" class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="gear-fill"></b-icon>
      </b-input-group-prepend>
      <b-form-input v-model="pwdFiled" type="password" placeholder="mot de passe"></b-form-input>
    </b-input-group>
    </div></div>
    </p>

    <div slot="footer" class="button-container">
      <b-collapse id="collapse-4" v-model="loginError" class="mt-2">
      Erreur d'authentification, veuillez vérifier vos codes
    </b-collapse>

      <base-button size="lg" :show="!inLoad" @click="login()">
        Login
      </base-button><br><br>
      <a href="/inscription" class="nav-item">Nouveau client?</a>
    </div>
  </card>
</b-overlay>
  </div>
  </div>
</template>
<script>
import Vue from "vue";
import {SuperAdminRouter, AgentRouter, ClientRouter} from "@/router/initRouter";
export default {
  components: {
  },
  props: {
    withCid: {
      type: Boolean,
      default: () => {
        return false;
      },
    }
  },
  data() {
    return {
      loginFiled: '',
      pwdFiled: '',
      loginError: false,
      inLoad : false,
      requireAuth : false,
    };
  },
  methods: {
    checkAndGo(){
      const admin = Vue.$cookies.get("user");
      if(admin) {
        this.addRouterAndGo(admin)
      } else {
        this.requireAuth = true;
      }
    },

    addRouterAndGo(admin) {
      if(admin.type === "ROOT") {
        this.$router.addRoute(SuperAdminRouter);
        this.$router.push('/superadmin');
        return
      }

      if(admin.type === "ADMIN") {
        this.$router.addRoute(SuperAdminRouter);
        this.$router.push('/superadmin')
        return
      }

      if(admin.type === "AGENT") {
        this.$router.addRoute(AgentRouter);
        if(this.$props.withCid === false) {
          this.$router.push('/agent')
        } else {
          this.$router.push('/cmd/'+this.$route.params.id)
        }        
        return
      }

      if(admin.type === "CLIENT") {
        this.$router.addRoute(ClientRouter);
        this.$router.push('/client')   
        return
      }
      
    },

    login(){
      let $onNext = (response) => {
        if(response.data.length === 0) {
          this.loginError = true;
          return;
        }
        Vue.$cookies.set('user',response.data[0]);
        setTimeout(()=> {
          this.inLoad = false;
          this.addRouterAndGo(response.data[0])
        }, 3000)
        
      };

      let $onError = (error) => {
        this.inLoad = false;
      };

      let $onComplete = () => {
        
      };

      this.inLoad = true;
      this.loginError = false;
      this.__.httpAction({
        methode: 'POST',
        route: '/auth',
        data: {login: this.loginFiled, pwd: this.pwdFiled},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {    
    //Vue.$cookies.remove("user")
    this.checkAndGo()
  }
};
</script>
<style scoped>
.display_user_logo {
    margin: 0;
    text-align: center;
}
.display_user_logo img{
        margin: 30px 0;
}
</style>