<template>
  <b-overlay
    :show="isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
  >
    <div v-if="!isOnLoad" class="defaultStyle" :style="_customCss">
      <span class="title" v-if="_showTitle">{{_customTitle || grp?.name}}<br></span>
        <b-form-group
          v-slot="{ grpId }"
          v-if="grp.type === 'SELECT' || grp.type === 'RADIO' || grp.type === 'SELECT_TPL'"
        >
          <b-form-checkbox-group
            v-model="_parentItem[_warpper]"
            :options="list"
            :aria-describedby="grpId"
            switches
            size="sm"
            stacked
            @change="_cb()"
          ></b-form-checkbox-group>
        </b-form-group>

        <b-form-select
          v-if="grp.type === 'DD'"
          :disabled = "!isEditable ?? list.length === 1"
          v-model="_parentItem[_warpper]"
          :options="list"
          class="form-control"
          @change="_cb(list.filter(e => e.value === _parentItem[_warpper]))"
      ></b-form-select>
      
      <b-form-select
          v-if="grp.type === 'EXT_DD'"
          v-model="_parentItem[_warpper]"
          :options="list"
          class="form-control"
          style="width:50%; float: left"

          @change="loadChildData(); "
      ></b-form-select>

      <b-form-select
          v-if="grp.type === 'EXT_DD'"
          v-model="_parentItem[_warpperSub]"
          :options="sub"
          style="width:50%; float: left"
          class="form-control"
      ></b-form-select>

     
    </div>
  </b-overlay>
</template>
<script>
import { computed } from 'vue';

  export default {
    name: 'selector',
    components: {

    },
    props: {
      _id: {
        type: String,
        default: '',
      },

      _customData: {
        type: Object,
        default: () => {},
      },
      
      _showTitle: {
        type: Boolean,
        default: true,
      },
      _customCss: {
        type: String,
        default: () => '',
      },

      _customTitle: {
        type: String,
        default: () => '',
      },

      _warpper: {
        type: String,
        default: () => '',
      },
      _warpperSub: {
        type: String,
        default: () => '',
      },
      
      _parentItem: {
        type: Object,
        default: () => {},
      },

      _editable: {
        type: Boolean,
        default: true,
      },

      _asFilter: {
        type: Boolean,
        default: false,
      },

      _cb: {
        type: Function,
        default: () => {()=>{}},
      },

      _initFn: {
        type: Function,
        default: () => {()=>{}},
      },
    },
    data() {
      return {
        isOnLoad: false,
        isEditable: true,
        isFilter: false,
        grpId: 'dd',
        grp: {},
        list: [],
        sub: [],
      }
    },

    methods: {
      setCustomList() {
        if(this.$props._customData) {
          this.list = this.$props._customData.list;
          this.grp = this.$props._customData.grp;
          this.isEditable = this.$props._customData.editable;
        }
      },

      loadData() {
        if(this.$props._id === '') {
          return
        }
        let $onNext = (response) => {
          this.grp = response.data[0];
          this.isEditable = this.$props._editable;
          this.isFilter = this.$props._asFilter;
          if(this.isFilter === true) {
            this.grp.type = 'DD';
          }
          this.loadSubData()
          this.$props._cb()
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/grp_opts/'+this.$props._id,
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },

      loadSubData() {
        if(this.grp._id === '') {
          return
        }
        let $onNext = (response) => {
          var ext = this.isFilter ? [{text: '.......', value: ''}] : [];
          this.list = ext.concat(response.data.filter(e => e.isActive).map(c => ({text: c.name, value: (this.grp.useTitle ? c.name: c._id) })));
          if(this.grp.type === 'EXT_DD' && this.$props._parentItem[this.$props._warpper] !== '') {
            this.loadChildData()
          }
          this.$props._initFn(this.list)
          //this.updateData();
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/opts/list/'+this.grp._id,
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },

      loadChildData() {
        if(this.$props._parentItem[this.$props._warpper] === '') {
          return
        }
        let $onNext = (response) => {
          this.sub = response.data.filter(e => e.isActive).map(c => ({text: c.name, value: (this.grp.useTitle ? c.name: c._id) }));
          //this.updateData();
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/opts/childs/'+this.$props._parentItem[this.$props._warpper],
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      }
     },

    mounted() {
      this.loadData();
      setTimeout(()=> {
        this.setCustomList();
      }, 1000)
      
    },

    computed: {
      // varPath: function() {
      // return this.$props.parentItem[this.$props._model];
      // },
    },

    watch: {  
      _customData: function () {
        console.log("ahwaaaaa")
      this.setCustomList();
    },
  },
  }
</script>
<style scoped>
.defaultStyle {
  width: 100%;
  margin-bottom:20px;
}
.defaultStyle .title {
    font-weight: 500;
    font-size: large;
    line-height: 40px;
}
@media (max-width: 1024px) {
  .defaultStyle .title {
    font-weight: 500;
    font-size: small;
    line-height: 15px;
}
}
</style>
