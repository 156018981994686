<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
      <b-modal 
          v-model="showUploader"  
          :hide-footer= "true"
          size="xl"
          :hide-header= "true"
          >
            <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
          </b-modal>

      <div class="editionPanel">
        <div class="row">


          <div class="col-md-6 pr-md-1">
              <base-input
                placeholder="Matricule"
                v-model="__item.matricule"
                type = "string"
                :error = "errorCtrl"
              >
              </base-input>
            </div>


           
            <div class="col-md-6 pr-md-1">
                <selector _id="665fa043ca0c5b91a6d82d86" :_parentItem="__item" _warpper="typeId"></selector>
            </div>

            <div class="col-md-12 pr-md-1">
                <selector _id="6653da670a156ff56b75be46" :_parentItem="__item" _warpper="marqueId" _warpperSub="modelId" _customCss="background-color:#0e101a; padding: 3%; border-radius: 10px; float: left"></selector>
            </div>

          <div class="col-md-6 pr-md-1">
                <selector :_customData="carbuListStructure" :disabled=false :_showTitle='true' :_parentItem="__item" _warpper="carbuId"></selector>
            </div>

            <div class="col-md-6 pr-md-1">
                <selector :_customData="huileListStructure" :disabled=false :_showTitle='true'  :_parentItem="__item" _warpper="huileId"></selector>
            </div>


            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
            <label for="datepicker-ct">Mise en circulation</label>
             <b-form-datepicker id="datepicker-ct" v-model="__item.dateCT" :dark=true class="mb-1" placeholder="" nav-button-variant="secondary"></b-form-datepicker>
            </div>

            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
            <label for="datepicker-dateEA">Date assurance</label>
             <b-form-datepicker id="datepicker-dateEA" v-model="__item.dateEA" :dark=true class="mb-1" placeholder="" nav-button-variant="secondary"></b-form-datepicker>
            </div>

            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
            <label for="datepicker-dateVT">Date visite technique</label>
             <b-form-datepicker id="datepicker-dateVT" v-model="__item.dateVT" :dark=true class="mb-1" placeholder="" nav-button-variant="secondary"></b-form-datepicker>
            </div>


            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
                <b-button @click="showUploader = true; onEditionPic = 'pic1'" v-if="__item.pic1 ===''" size="sm" >
                Parcourir
                </b-button>
                <img :src="getTof(__item.pic1)" v-else @click="showUploader = true; onEditionPic = 'pic1'" style="max-width: 98%; margin:1%"/>
            </div>

            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
                <b-button @click="showUploader = true; onEditionPic = 'pic2'" v-if="__item.pic2 ===''" size="sm" >
                Parcourir
                </b-button>
                <img :src="getTof(__item.pic2)" v-else @click="showUploader = true; onEditionPic = 'pic2'" style="max-width: 98%; margin:1%"/>
            </div>

            <div class="col-md-4 pr-md-1" style="text-align: center; margin-bottom: 5px">
                <b-button @click="showUploader = true; onEditionPic = 'pic3'" v-if="__item.pic3 ===''" size="sm" >
                Parcourir
                </b-button>
                <img :src="getTof(__item.pic3)" v-else @click="showUploader = true; onEditionPic = 'pic3'" style="max-width: 98%; margin:1%"/>
            </div>

            

        <div class="text-right">
          <div class="col-md-12" style="margin-top:5px;">
            
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>
        </div>
      </div></div>
    </b-overlay>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },

    __prdList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      fullList: [],
      onEditionPic: '',
      showUploader: false,
      carbuListStructure: null,
      huileListStructure: null,
      dataUploaderPayload: {
        folder: "clt_",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customCatEditModal"
      },
      errorCtrl: [],
    }
  },
  methods: {
    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // return 'http://localhost:5000/uploads/'+c.split("/uploads/")[1];
      // return './uploads/'+c.split("/uploads/")[1];
    },

    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },

    setCarbuList() {
      if(this.$props.__prdList.length === 0) 
        return
      this.fullList = this.$props.__prdList;
      this.carbuListStructure = {}
      this.carbuListStructure.editable = true;
      this.carbuListStructure.grp = {
        type: 'DD',
        name: 'Carburant'
      }
      this.carbuListStructure.list = this.$props.__prdList.filter(e => e.catID === '665ba890bc69edc5d42c031a').map(c => ({text: c.name, value: c._id, extra: c.catTitle }));
      },

      setHuileList() {
      if(this.$props.__prdList.length === 0) 
        return
      this.fullList = this.$props.__prdList;
      this.huileListStructure = {}
      this.huileListStructure.editable = true;
      this.huileListStructure.grp = {
        type: 'DD',
        name: 'Huile moteur'
      }
      this.huileListStructure.list = this.$props.__prdList.filter(e => e.subcatID === '665e782789645eb5bd7e1391').map(c => ({text: c.name, value: c._id, extra: c.catTitle }));
      },

  },
  mounted() {
    this.setCarbuList();this.setHuileList();
    this.dataUploaderPayload.clientID = this.$props.__item.pID;
    this.dataUploaderPayload.folder = 'clt_'+this.$props.__item.pID;
    
    this.dataUploaderPayload.onFireCB = (params) => {
      this.showUploader = false
      this.__item[this.onEditionPic] = params[0];
    }
    this._packID = this.__item.packID;
  },

  watch: {
    __item: {
      deep: true,
      handler(newValue, oldValue) {
          //this.setSubData()
      },
    },
  },


};
</script>
<style>
.h-auto {
  padding: 7px 10px !important;
  margin: 0px !important;
}
.modal-xl {
  max-width: 85% !important;
}
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
