<template>
  <div :class="byClient !== '' ? '' : 'row'">
    <div  :class="byClient !== '' ? 'col-md-12' : 'col-md-6'" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__catList="clientArray"
        :__clientID="byClient"
      ></Edit>
    </div>
    <h4>{{useTitle}}</h4>
    <ico-btn style="position: absolute; top: 15px; right:1%; z-index:1234" type="simple" icone="plus-circle" :cb="()=>{addAction()}"></ico-btn>

    <div :class="listStyle">
      <List
        :__list="dataArray"
        :__byClient = "byClient !== ''"
        :__isOnLoad="isListOnload"
        ></List>
    </div>
  </div>
</template>
<script>
import List from './List';
import Edit from './Edit';
export default {

  components: {
    List,Edit
  },
  props: {
    byClient: {
      type: String,
      default: () => {
        return '';
      },
    },

    useTitle:  {
      type: String,
      default: () => {
        return '';
      },
    },
  },

  data() {
    return {
      moduleRoute: '/bons',
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      dataArray: [],
      onGoingReq:[],
      clientArray: [],
      intervalID: null,
      timeoutVlue: 30000,
    };
  },

  methods: {
    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData()
        return
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },

    saveAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        //this.loadData(this.onEditionId, null, this.onEditionIdx);
        this.loadData()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].confirmed = val;
        this.loadData()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'confirmed']);
      data.confirmed = val;
      if(val === true)
        data.confirmDate = new Date()
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },


    editAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx)
      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    addAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data, () => {
          this.editAction(response.data);
        }
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      var payload = this.byClient === '' ? {} : {clientID: this.byClient}
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: payload,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },


    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray = [response.data[0]].concat(this.dataArray)
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
        var totalConsumed = 0
        this.dataArray.map(e => {
          if(e.consumed === true)
            totalConsumed += e.nbrpts;
        })
        this.$emit("set-consumed", totalConsumed)
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      var _route = '/bons';
      if(this.$props.byClient !== '') {
        _route+="/client/"+this.$props.byClient
      }
      
      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: _route,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadClient() {
      let $onNext = (response) => {
        this.clientArray= response.data.map(e => {
          return {
            id: e._id,
            name: e.name,
            solde: e.solde
          }
        })
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/clients',
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

  },

  mounted() {
    this.loadClient()
    if(this.$props.byClient !=='') {
      this.timeoutVlue =  60000;
    }
    
    this.intervalID = setInterval(() => {
        this.loadData();
    }, this.timeoutVlue);
    this.loadData();
  },

  computed: {
    listStyle() {
      return this.onEditionId !== null && this.byClient === '' ? 'col-md-6' : 'col-md-12';
    },
  },

  destroyed() {
    console.debug('destroyed');clearInterval(this.intervalID)
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style></style>
