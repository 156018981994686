<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
  <div style="float: right; margin-bottom: 3px" v-if="!showFilter && !__byItem"><b-button size="lg" variant=""  @click="showFilter = true"> Filtre</b-button></div>
    <div style="clear: both"></div>

    <div class="row serachPanel" v-if="__editMode === false && showCmds === -1  && __showFilter && showFilter" v-on:dblclick="showFilter = false">
      <div class="col-md-4"><span class="title">Recherche</span><base-input placeholder="nom, email, tél..." v-model="filterContainer.keySearch" type = "string"></base-input></div>
      <div class="col-md-2"><selector _id="665fa043ca0c5b91a6d82d86" :_editable="true" :_asFilter="true" :_initFn="setTypeVData" :_parentItem="filterContainer" _warpper="filter_typeV"></selector></div>
      <div class="col-md-2"><selector _id="6653da670a156ff56b75be46" :_editable="true" :_asFilter="true" :_initFn="setMarqueData" :_parentItem="filterContainer" _warpper="filter_marque"></selector></div>
      <div class="col-md-2"><selector _id='' :_customData="carbuListStructure" :_cb="setHuileFilter" :_showTitle='true' :_editable="true"  :_asFilter="true" :_parentItem="filterContainer" _warpper="filter_carbuId"></selector></div>
      <div class="col-md-2"><selector _id='' :_customData="huileListStructure" :_cb="setHuileFilter" :_showTitle='true' :_editable="true"  :_asFilter="true"  :_parentItem="filterContainer" _warpper="filter_huileId"></selector></div>
    </div>


    <div class="row">
    <div
      
      v-for="(item, index) in filtredList()"
      :key="index"
      :style=""
      :class="styleSublisteMode + isCurrent(index === showList, showCmds === item._id)"
      v-if="((__idxOnEdition === -1 && showCmds === -1) || (__idxOnEdition === index) || (showCmds === item._id))"
    >

    <div class="row">

      <div class="col-md-12">
        <div class="pic"><img :src="getTof(item.pic1)" /></div>
        <div class="preview">
        {{item.marqueName || '.'}}<br>
        {{item.typeId || '.'}}
        <h4>{{ item.matricule || '.' }}</h4>
        Carburant: {{item.carbuName || '.'}}<br>
        Huile: {{item.huileName || '.'}}<br>

        <div v-if="showMenu === index" style="color:white">
          <b>KM :</b> {{ item.km }}<br>
          <b>Mise en circu :</b> {{ item.dateCT }}<br>
          <b>Ech assurance :</b> {{ item.dateEA }}<br>
          <b>Date visite T :</b> {{ item.dateVT }}<br>
        </div>

        </div>

        


      </div>
      
      <div class="col-md-12">
        <div class="menu"  v-if="showCmds !== -1">
            <b-button size="sm" variant="danger" @click="showCmds = -1">
            Retour
          </b-button>
        </div>

        <div class="menu"  v-if="showCmds === -1">
        <ico-btn
          size="p"
          type="simple"
          icone="gear-fill"
          :cb="
            () => {
              dispatchEdit(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="warning"
          icone="trash"
          :cb="
            () => {
              dispatchDelete(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          alt="info"
          type="toggleOn"
          icone="info-circle-fill"
          :cb="
            () => {
              toggleMenu(index);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="simple"
          icone="list-check"
          :cb="
            () => {
              showCmds = item._id;
            }
          "
        ></ico-btn>

      </div>
      
      </div>
    </div>
      
    
    </div>
    <cmds v-if="showCmds !== -1" :byCars="showCmds" class="col-md-10"></cmds>
    </div>

    
  </b-overlay>
</template>
<script>
import cmds from '@/pages/cmds/index';
export default {
  components: {
    cmds,
  },
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    __prdList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    __showFilter: {
      type: Boolean,
      default: () => {
        return true;
      },
    },

    __modeClient:  {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  data() {
    return {
      filterContainer: { filter_typeV: '', filter_marque: '', keySearch: '', filter_carbuId: '', filter_huileId: '' },
      showCmds: -1,
      structure: { typeV: [], marque: []},
      fullList: [],
      showMenu: -1,
      showFilter: true,
      carbuListStructure: {},
      huileListStructure: {},
      showList: -1,
      appUrl: 'http://192.168.1.18:5000/shop/',
    };
  },
  methods: {
    getHuileName(id) {
      if(this.huileListStructure?.list?.length > 0) {
        var res = this.huileListStructure.list.filter(s => s.value === id)
        return (res.length === 1) ? res[0].text : ''
      }
      return  '';
    },

    getCarbuName(id) {
      if(this.carbuListStructure?.list?.length > 0) {
        var res = this.carbuListStructure?.list?.filter(s => s.value === id)
        return (res.length === 1) ? res[0].text : ''
      }
      return  '';
    },

    getMarqueName(id) {
      if(this.structure?.marque?.length>0) {
        var res = this.structure?.marque?.filter(s => s.value === id)
        if(res.length === 1)
          return res[0].text
        else 
          return ''
      }
      return  '';
    },

    filtredList() {
      var ret = this.fullList;
      if(this.filterContainer.filter_huileId)
        ret = ret.filter(e => e.huileId === this.filterContainer.filter_huileId)
      if(this.filterContainer.filter_carbuId)
        ret = ret.filter(e => e.carbuId === this.filterContainer.filter_carbuId)
      if(this.filterContainer.filter_typeV)
        ret = ret.filter(e => e.typeId === this.filterContainer.filter_typeV)
      if(this.filterContainer.filter_marque)
        ret = ret.filter(e => e.marqueId === this.filterContainer.filter_marque)
      if(this.filterContainer.keySearch)
        ret = ret.filter(e => e.matricule.includes(this.filterContainer.keySearch))
      return ret
    },

    setHuileFilter() {

    },

    setCarbuList() {
      
      if(this.$props.__prdList.length === 0 || this.carbuListStructure.editable) 
        return
      this.fullList = this.$props.__prdList;
      this.carbuListStructure = {}
      this.carbuListStructure.editable = true;
      this.carbuListStructure.grp = {
        type: 'DD',
        name: 'Carburant'
      }
      this.carbuListStructure.list = [{ text: '................', value: '', extra: '' }].concat(this.$props.__prdList.filter(e => e.catID === '665ba890bc69edc5d42c031a').map(c => ({text: c.name, value: c._id, extra: c.catTitle })));
      },

      setHuileList() {

      if(this.$props.__prdList.length === 0 || this.huileListStructure?.editable) 
        return
        
      this.fullList = this.$props.__prdList;
      this.huileListStructure = {}
      this.huileListStructure.editable = true;
      this.huileListStructure.grp = {
        type: 'DD',
        name: 'Huile moteur'
      }
      this.huileListStructure.list = [{ text: '................', value: '', extra: '' }].concat(this.$props.__prdList.filter(e => e.subcatID === '665e782789645eb5bd7e1391').map(c => ({text: c.name, value: c._id, extra: c.catTitle })));
      },

    setTypeVData(list){
      this.structure.typeV = list
    },

    setMarqueData(list){
      this.structure.marque = list
    },


    isCurrent(current, showCmd) {
      var _showCmd = showCmd ? ' withSub' : '';
      if (this.showList !== -1) return current+_showCmd ? "  actif" : " flowted";
      return _showCmd;
    },

    toggleMenu(index) {
      if (this.showMenu != index) {
        this.showMenu = index;
      } else {
        this.showMenu = -1;
      }
    },

    toggleList(index) {
      if (this.showList != index) {
        this.showList = index;
      } else {
        this.showList = -1;
      }
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // if(c)
      //   return "http://localhost:5000/uploads/" + c.split("/uploads/")[1];
      // return ''
      //return "./uploads/" + c.split("/uploads/")[1];
    },

    formatList() {
      this.fullList = this.fullList.map(e => {
        return {
          ...e,
          marqueName: this.getMarqueName(e.marqueId),
          carbuName: this.getCarbuName(e.carbuId),
          huileName: this.getHuileName(e.huileId)
        }
      })
    }

  },
  
  computed: {
    styleSublisteMode() {
      if(this.__modeClient) {
        return this.__editMode !== true ? "clientMode" : "clientMode";
      }
      if(!this.__showFilter)
        return this.__editMode !== true ? "listeMode col-md-3" : "editMode col-md-12";
      return this.__editMode !== true ? "listeMode col-md-3" : "editMode col-md-12";
    },
  },

  watch: {
    __isOnLoad: function () {},
    __prdList: function () {
      this.setCarbuList()
      this.setHuileList()
    },

    __list: {
      deep: true,
      handler(newValue, oldValue) {
        this.fullList = this.$props.__list ;
        this.formatList()
      },
    },
    structure: {
      deep: true,
      handler(newValue, oldValue) {
        this.formatList()
      },
    },
  },
  mounted() {
    this.setCarbuList()
    this.setHuileList()
    
    setTimeout(()=> {
      this.fullList = this.$props.__list ;
      this.formatList()
    }, 100)
  },
};
</script>
<style scoped>
.clientMode {
  width: 94%;
  padding: 15%;
  margin: 3%;
  margin-bottom: 10px;
  border-radius: 25px;
  min-height: 40px;
  background-color: #fff;
  border: 3px solid #151c37;
}
.clientMode .menu {
  float: right;
  border-radius: 10px;
  background-color: #1b1b29;
}
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.listeMode {
  padding: 3%;
  margin-bottom: 20px;
  border-radius: 30px;
  min-height: 50px;
  background-color: #fff;
  margin: 15px;
}
.listeMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
    margin: 20px 0 0;
    width: 120px;
}
.pic {
  width: 100%;
}
.pic img {
}
.preview {
    float: left;
    font-size: 14px;
    margin-top: 20px;
    text-transform: uppercase;
}

.preview em {
  font-weight: lighter;
  font-size: 12px;
}

.withSub {
  background-color: #8a9fff;
  color: black !important;
}
.withSub .menu {
  background-color: #8a9fff !important;
}
.flowted {
  filter: blur(3.5px);
}

.actif {
  filter: none;
  background-color: #131016;
}

.shop {
  margin-right: 5px;
  color: #2bffc6;
}
.service {
  margin-right: 5px;
  color: #ff8d72;
}
.lubrifiant {
  margin-right: 5px;
  color: #ffd600;
}
.carburant {
  margin-right: 5px;
  color: #b1cf0c;
}
.serachPanel {
  padding: 3%;
  background-color: #171f3c;
  border-radius: 30px;
  margin:20px 0;
}
</style>
