<template>
  <div :class="getClass" @click="handleClick()">
    <b-icon :icon="icone" class="bIcone"></b-icon>
  </div>
</template>
<script>
export default {
  name: "ico-btn",
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    icone: {
      type: String,
      default: () => {
        return '';
      },
    },
    size: {
      type: String,
      default: () => {
        return '';
      },
    },
    type: {
      type: String,
      default: () => {
        return '';
      },
    },
    custom: {
      type: String,
      default: () => {
        return '';
      },
    },
    cb: {
      type: Function,
      default: () => {
        return ()=>{};
      },
    },
  },
  computed: {
    getClass() {
      const init = 'btnIco';
      const size = (this.size && this.size == 'p') ? 'litle ':'big ';
      const st = size+init;
      if(this.disabled) {
        return 'btnIcoDisabled';
      }
      switch(this.type) {
        case 'simple':
        return st+' simpleBtn';
        case 'warning':
        return st+' warningBtn';
        case 'toggleOn':
        return st+' toggleOn '+this.custom;
        case 'toggleOff':
        return st+' toggleOff';
        
      }
    },
  },
  methods: {
    handleClick(evt) {
      if(this.cb) {
        this.cb()
        return;
      }
      this.$emit("click", evt);
    }
  }
};
</script>
<style>
.litle .bIcone {
    width: 20px;
    height: 20px;
    margin-top: 10px
}
.big .bIcone {
  width:25px;
  height: 25px; 
  margin-top: 8px;
}
.litle {
  width: 25px;
  height: 25px;
}
.big {
  width: 34px;
  height: 34px;
}
.btnIco {
    border-radius: 50%;
    text-align: center;
    background-color: #525f7f;
    color: #FFF;
    float: right;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin: 5px;
}
.btnIcoDisabled {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-left: 1px;
  text-align: center;
  background-color: #393d49;
  color: #6a6e7a;
  float: right;
}

.simpleBtn {
  background-color: #151c37;
  cursor: pointer;
  color:#ffffff;
}
.simpleBtn:hover {
  background-color: #ffffff;
    color:#151c37;
}
.warningBtn {
  background-color: #ffffff;
  cursor: pointer;
   color: #E6302F;
}
.warningBtn:hover {
  background-color: #000000;
}
.toggleOff {
  background-color: #000;
  cursor: pointer;
}
.toggleOff:hover {
  background-color: rgb(27, 25, 25);
}
.toggleOn {
  background-color: rgb(255 255 255);
  cursor: pointer;
  color: #e6302f;
}
.toggleOn:hover {
  background-color: rgb(0 0 0);
}
.orange {
  background-color: rgb(251, 130, 0);
}
.purple {
  background-color: rgb(155, 0, 251);
}
.pink {
  background-color: rgb(251, 0, 201);
}
.green {
  background-color: rgb(84 89 5);
}
</style>
