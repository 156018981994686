<template>
  <div class="form-group"
       :class="{
          'input-group': hasIcon,
          'input-group-focus': focused
       }">
    <slot name="label">
      <label v-if="label" class="control-label">
        {{label}}
      </label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <b-alert :show="errorMsg !== ''" variant="danger">{{errorMsg}}</b-alert>
      <input
        :type="isNumber"
        :value=value
        v-bind="$attrs"
        v-on="listeners"
        class="form-control"
        id="tooltip-target-1"
        aria-describedby="addon-right addon-left">
        
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
      type: {
        type: String,
        default: () => {
          return "texte";
        },
        description: "Input label"
      },
      error: {
        type: Array,
        default: () => {
          return [];
        },
        description: "Input label"
      },
      range: {
        type: Object,
        default: () => {
          return null;
        },
        description: "Input label"
      },
      label: {
        type: String,
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      addonRightIcon: {
        type: String,
        description: "Input icon on the right"
      },
      addonLeftIcon: {
        type: String,
        description: "Input icon on the left"
      },
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    data() {
      return {
        focused: false,
        errorMsg: '',
      }
    },
    computed: {
      isNumber() {
        return this.$props.type === "num" ? "number" : this.$props.type === "pwd" ? 'password' : "text";
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          blur: this.onBlur,
          focus: this.onFocus
        }
      }
    },
    methods: {
      getStatus(id) {
        const itm = this.$props.error.filter(e => e.id === id)
        if(itm.length === 0) {
          this.$props.error.push({id: id, err: '', isValid: true})
          return this.$props.error[this.$props.error.length -1];
        } else {
          return itm[0];
        }
      },
      onInput(evt) {
        if(this.$props.type === 'num') {
          evt.target.className = "form-control";
          const err = this.getStatus(evt.target.placeholder);
          err.isValid = true;
          err.err = this.errorMsg =  '';
          if(this.$props.range?.min > Number(evt.target.value)) {
            err.isValid = false;
            err.err = this.errorMsg = 'Valeur min autorisée = '+this.$props.range.min;
            evt.target.className = "form-control error";
          }
          if(this.$props.range?.max < Number(evt.target.value)) {
            err.isValid = false;
            err.err = this.errorMsg = 'Valeur max autorisée = '+this.$props.range.max;
            evt.target.className = "form-control error";
          }
          this.$emit('input', Number(evt.target.value))
        } else {
          this.$emit('input', evt.target.value)
        }
        
      },
      
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      }
    }
  }
</script>
<style>
.error {
  background-color: brown !important;
}
.alert{
  position: absolute !important;
  font-size: 10px !important;
  padding: 5px !important;
  top: 30px !important;
  z-index: 1000;
}
</style>
