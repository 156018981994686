import Vue from 'vue';
import Router from 'vue-router';
import CheckScreen from "@/layout/checkScreen/checkScreen.vue";
import offlineLayout from "@/layout/checkScreen/layout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";


import clientLayout from '../layout/clientPanel/SampleLayout.vue';
import agentLayout from '../layout/agentPanel/SampleLayout.vue';
import rootLayout from '../layout/rootPanel/SampleLayout.vue';
import adminLayout from '../layout/adminPanel/SampleLayout.vue';


// Admin pages
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Clients = () => import(/* webpackChunkName: "common" */ "@/pages/clients/index.vue");
const logout = () => import(/* webpackChunkName: "common" */ "@/pages/logout.vue");
const Cmd = () => import(/* webpackChunkName: "common" */ "@/pages/cmd.vue");
const HomeClient = () => import(/* webpackChunkName: "common" */ "@/pages/client.vue");
const Agents = () => import(/* webpackChunkName: "common" */ "@/pages/agents/index.vue");
const Opts_Grp = () => import(/* webpackChunkName: "common" */ "@/pages/opts_grp/index.vue");
const Cats = () => import(/* webpackChunkName: "common" */ "@/pages/prd_cats/index.vue");
const Cars = () => import(/* webpackChunkName: "common" */ "@/pages/cars/index.vue");
const Prods = () => import(/* webpackChunkName: "common" */ "@/pages/prods/index.vue");
const Cmds = () => import(/* webpackChunkName: "common" */ "@/pages/cmds/index.vue");
const Bons = () => import(/* webpackChunkName: "common" */ "@/pages/bons/index.vue");

export const ClientRouter = {
  path: '/client',
  name: 'Client',
  component: clientLayout,
  children: [
    {
      path: '/info',
      name: 'info',
      components: { default: HomeClient },
      props: { default: () => ({}) }
    }
  ]
  
};
export const AgentRouter = {
  path: '/agent',
  name: 'Agent',
  redirect: '/info',
  component: agentLayout,
  children: [
    {
      path: '/info',
      name: 'info',
      components: { default: Cmd },
      props: { default: () => ({}) }
    },
    {
      path: '/cmd/:id',
      name: 'cmd',
      components: { default: Cmd },
      props: { default: () => ({}) }
    },
  ]
  
};

export const SuperAdminRouter = {
    path: '/superadmin',
    name: 'Super admin',
    redirect: '/products',
    component: rootLayout,
    children: [
      {
        path: '/options',
        name: 'Gestion des options',
        components: { default: Opts_Grp },
        // props: { default: () => ({ PID: 38 }) }
      },
      {
        path: '/clients',
        name: 'Gestion des clients',
        components: { default: Clients },
        // props: { default: () => ({ PID: 38 }) }
      },

      {
        path: '/cars',
        name: 'Gestion des voitures',
        components: { default: Cars },
        // props: { default: () => ({ PID: 38 }) }
      },

      {
        path: '/cmds',
        name: 'Gestion des achats',
        components: { default: Cmds },
        // props: { default: () => ({ PID: 38 }) }
      },

      {
        path: '/bons',
        name: 'Gestion des bons d\'achats',
        components: { default: Bons },
        // props: { default: () => ({ PID: 38 }) }
      },

      
      {
        path: '/categorys',
        name: 'Gestion des catégories',
        components: { default: Cats },
        // props: { default: () => ({ PID: 38 }) }
      },
      {
        path: '/products',
        name: 'Gestion des produits',
        components: { default: Prods },
        // props: { default: () => ({ PID: 38 }) }
      },
      {
        path: '/agents',
        name: 'Gestion des agents',
        components: { default: Agents },
        // props: { default: () => ({ PID: 38 }) }
      },
      
      
    ]
  };
const routes = [
  {
    path: "/",
    component: offlineLayout,
    redirect: '/authentification',
    children: [
      {
        path: '/inscription',
        name: 'inscription',
        components: { default: Clients },
        props: { default: () => ({ NEW_CLT: true }) }
      },
      {
        path: '/authentification',
        name: 'authentification',
        components: { default: CheckScreen },
        props: { default: () => ({}) }
      },
      {
        path: '/deconnexion',
        name: 'deconnexion',
        components: { default: logout },
      },
      {
        path: '/shop/:id',
        name: 'shop',
        components: { default: CheckScreen },
        props: { default: () => ({withCid: true}) }
      },
      
    ]
  },
  { path: "*", redirect: '/'},
];

Vue.use(Router);

export default new Router({routes: routes, mode: 'history'});
