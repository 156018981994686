<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
    <div style="float: right; margin-bottom: 3px" v-if="!showFilter && !__byItem"><b-button size="lg" variant=""  @click="showFilter = true"> Filtre</b-button></div>
    <div style="clear: both"></div>
    <div class="row serachPanel" v-on:dblclick="_hideFilter()" v-if="showFilter">
      <div class="col-md-3">
        Recherche<base-input
          placeholder="nom, tél, matricule..."
          v-model="filterContainer.keySearch"
          type="string"
        ></base-input>
      </div>
      <div class="col-md-2" v-if="allowDelete()">
        DU
        <b-form-datepicker
          id="datepicker-dateDU"
          v-model="filterContainer.fromDate"
          :dark=true
          class="mb-1"
          placeholder="DU"
          nav-button-variant="secondary"
        ></b-form-datepicker>
      </div>

      <div class="col-md-2" v-if="allowDelete()">
        AU
        <b-form-datepicker
          id="datepicker-dateAU"
          v-model="filterContainer.toDate"
          :dark=true
          class="mb-1"
          placeholder="AU"
          nav-button-variant="secondary"
        ></b-form-datepicker>
      </div>
      <div class="col-md-1" v-if="showExtraFilter('article')">
        <selector
          _id="665ba0cb284f469df5af7980"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setArticleCatData"
          :_parentItem="filterContainer"
          _warpper="filter_articleCat"
        ></selector>
      </div>
      <div class="col-md-1" v-if="showExtraFilter('station')">
        <selector
          _id="6653d9d70a156ff56b75be40"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setStationsData"
          :_parentItem="filterContainer"
          _warpper="filter_stationID"
        ></selector>
      </div>
      <div class="col-md-1" v-if="showExtraFilter('typev')">
        <selector
          _id="665fa043ca0c5b91a6d82d86"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setVTypeData"
          :_parentItem="filterContainer"
          _warpper="filter_voitureType"
        ></selector>
      </div>
      <div class="col-md-1" v-if="showExtraFilter('pack')">
        <selector
          _id="6657a83d5b58518ed52044ad"
          :_editable="true"
          :_asFilter="true"
          :_initFn="setPacksData"
          :_parentItem="filterContainer"
          _warpper="filter_packID"
        ></selector>
      </div>
    </div>

    <div class="row">
      <div
        :class="styleSublisteMode + isCurrent(index === showList)"
        v-for="(item, index) in filtredList()"
        :key="index"
        :style=""
      >
        <div class="row">
          <div class="col-md-4">
            <b-icon
              :icon="getIcon(item.articleType)"
              :class="getClass(item.articleType)"
            ></b-icon>
            {{ item.articleName }} {{ getQte(item) }}
            <br />
            <em style="font-size: 11px">{{ formatDate(item.createdAt) }}</em>

            <div v-if='showList === index'>

              <ul>
                <li v-for="(ic, idxc) in item.data">{{ ic.title }} : {{ ic.val }}</li>
              </ul>
            </div>
          </div>

          <div class="col-md-3" v-if="!__byClient && !__byCars" >
            Client : {{ item.clientName }}
            <br />
            <em style="font-size: 11px">{{ item.clientNumber }}</em>
          </div>
          <div class="col-md-3" v-if="!__byCars">
            <b-icon icon="truck" style="color: cyan"></b-icon>
            {{ item.voitureName }}
            <br />
            <em style="font-size: 11px">{{ item.voitureType }}</em>
          </div>

          <div :class="__byCars ? 'col-md-3' : 'col-md-1'" v-if="!__byAgent">
            <b-icon icon="person-circle" style="color: blue"></b-icon>
            {{ item.agentName }}
            <br />
            <em style="font-size: 11px">{{ item.stationeName }}</em>
          </div>

          <div :class="__byClient || __byCars ? 'col-md-4' : (__byAgent ? 'col-md-2' : 'col-md-1')">
            <div class="menu">
              <ico-btn
              size="p"
              alt="info"
              type="toggleOn"
              icone="info-circle-fill"
                :cb="
                  () => {
                    showList = showList === -1 ? index : -1
                  }
                "
              ></ico-btn>
              <ico-btn
                size="p"
                type="warning"
                icone="trash"
                v-if="allowDelete()"
                :cb="
                  () => {
                    dispatchDelete(item._id);
                  }
                "
              ></ico-btn>

              <b-icon icon="caret-up-fill" style="color: green"></b-icon>
              {{ item.solde }} .
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
export default {
  components: {},
  props: {
    __byClient: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __byAgent: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __byArticle: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __byCars: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __byItem: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      filterContainer: { fromDate: "", toDate: "", keySearch: "", filter_articleCat: "", filter_stationID: "", filter_voitureType : "", filter_packID: "" },
      structure: { vtype: [], packs: [], stations: [], ptype: [] },
      fullList: [],
      showMenu: -1,
      showFilter: false,
      showList: -1,
    };
  },
  methods: {
    _showFilter() {
      this.showFilter = true
    },
    _hideFilter() {
      this.showFilter = false
    },
    getQte(item) {
      var ret = item.data.filter((i) => i.id === "qte");
      if (ret.length === 1) return "(" + ret[0].val + ")";
      return "";
    },

    formatDate(d) {
      var dt = new Date(d);
      return (
        "Le " +
        dt.getDate() +
        "/" +
        dt.getMonth() +
        "/" +
        dt.getFullYear() +
        " a " +
        dt.getHours() +
        ":" +
        dt.getMinutes()
      );
    },

    getStationName(id) {
      if (this.structure.stations.length > 0) {
        var res = this.structure.stations.filter((s) => s.value === id);
        return res[0].text;
      }
      return "";
    },

    filtredList() {
      var ret = this.fullList;
      if (this.filterContainer.filter_articleCat)
        ret = ret.filter((e) => e.articleType === this.filterContainer.filter_articleCat);

      if (this.filterContainer.filter_voitureType)
        ret = ret.filter((e) => e.voitureType === this.filterContainer.filter_voitureType);
      
      if (this.filterContainer.filter_packID)
        ret = ret.filter((e) => e.clientPack === this.filterContainer.filter_packID);

      if (this.filterContainer.filter_stationID)
        ret = ret.filter(
          (e) => e.stationID === this.filterContainer.filter_stationID
        );

      if (this.filterContainer.fromDate)
        ret = ret.filter((e) => e.createdAt >= this.filterContainer.fromDate);

      if (this.filterContainer.toDate)
        ret = ret.filter((e) => e.createdAt <= this.filterContainer.toDate);

      if (this.filterContainer.keySearch)
        ret = ret.filter((e) =>
          e.clientName?.includes(this.filterContainer.keySearch) ||
          e.agentName?.includes(this.filterContainer.keySearch) ||
          e.articleName?.includes(this.filterContainer.keySearch) ||
          e.voitureName?.includes(this.filterContainer.keySearch) ||
          e.clientNumber?.includes(this.filterContainer.keySearch) 

        );
      return ret;
    },

    setVTypeData(list) {
      this.structure.vtype = list;
    },

    setArticleCatData(list) {
      this.structure.ptype = list;
    },

    setPacksData(list) {
      this.structure.packs = list;
    },

    setStationsData(list) {
      this.structure.stations = list;
    },

    isCurrent(current) {
      if (this.showList !== -1) return current ? "  actif" : " flowted";
      return "";
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    formatList() {
      this.fullList = this.fullList.map((e) => {
        return {
          ...e,
          stationeName: this.getStationName(e.stationID),
        };
      });
    },

    getIcon(type) {
      switch (type) {
        case "Shop":
          return "shop";
        case "Service":
          return "tools";
        case "Carburant":
          return "speedometer";
        case "Lubrifiant":
          return "wrench";
      }
      return "shop";
    },

    getClass(type) {
      switch (type) {
        case "Shop":
          return "shop";
        case "Service":
          return "service";
        case "Carburant":
          return "carburant";
        case "Lubrifiant":
          return "lubrifiant";
      }
      return "shop";
    },

    allowDelete() {
        return !this.$props.__byClient && !this.$props.__byCars && !this.$props.__byAgent   && !this.$props.__byArticle
    },

    showExtraFilter(v = '') {
      if(v === 'pack') {
        return !this.$props.__byClient && !this.$props.__byCars && !this.$props.__byAgent  
      }

      if(v === 'station') {
        return !this.$props.__byClient && !this.$props.__byCars && !this.$props.__byAgent  
      }

      if(v === 'article') {
        return !this.$props.__byArticle
      }

      if(v === 'typev') {
        return !this.$props.__byCars
      }
      return true
      return !this.$props.__byClient
    }
  },

  computed: {
    styleSublisteMode() {
      return this.__editMode !== true
        ? "listeMode col-md-12"
        : "editMode col-md-12";
    },
  },

  watch: {
    __list: {
      deep: true,
      handler(newValue, oldValue) {
        this.fullList = this.$props.__list;
        this.formatList();
      },
    },
    structure: {
      deep: true,
      handler(newValue, oldValue) {
        console.log("newValue");
        this.formatList();
      },
    },
  },
  
  mounted() {
    if(this.__byItem) {
      this.showList = 0
    }
    //this.formatList()
  },
};
</script>
<style scoped>
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.listeMode {
  padding: 3%;
  margin-bottom: 20px;
  border-radius: 30px;
  min-height: 50px;
  background-color: #fff;
}
.listeMode .menu {
  float: right;
  border-radius: 30px;
  background-color: #e9e9e9;
  max-width: 170px;
  min-height: 120px;
  padding: 10px
}
@media (max-width: 1024px) {.listeMode .menu {max-width: 70px;min-height: 325px;}}
.pic {
  width: 17%;
  margin-right: 3%;
  float: left;
}
.pic img {
  border-radius: 50%;
}
.preview {
  float: left;
  font-weight: bolder;
}

.preview em {
  font-weight: lighter;
  font-size: 12px;
}

.flowted {
  filter: blur(3.5px);
}

.actif {
  filter: none;
  background-color: #131016;
}

.shop {
  margin-right: 5px;
  color: #2bffc6;
}
.service {
  margin-right: 5px;
  color: #ff8d72;
}
.lubrifiant {
  margin-right: 5px;
  color: #ffd600;
}
.carburant {
  margin-right: 5px;
  color: #b1cf0c;
}
.serachPanel {
  padding: 3%;
  background-color: #171f3c;
  border-radius: 30px;
  margin:20px 0;
}
</style>
