<template>
  <div class="contentAgent">
    <b-overlay
      :show="isOnLoad()"
      variant="white"
      rounded
      opacity="0.20"
      spinner-variant="primary"
      spinner-big
      style="float: left; width: 100%"
    >
    <fade-transition :duration="100" mode="out-in">
     
      <div class="row">
        <div class="col-md-12" style="margin-top:-55px"><h3 style="margin-bottom:0px">Agent : {{ agent.name }}</h3><h4>Station : {{station}}</h4></div>
        <div class="col-md-6"><router-view></router-view></div>
        <div class="col-md-6"><cmds v-if="agent.pid!==''" style="opacity:1" :byAgent="agent._id"></cmds></div>
      </div>
      
    </fade-transition>
  </b-overlay>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import cmds from '@/pages/cmds/index.vue';
import Vue from "vue";
export default {
  components: {
    FadeTransition,
    cmds
  },
  data() {
    return {
      agent: {pid:''},
      station: '',
      onGoingReq: []
    };
  },
  methods: {
    isOnLoad() {
      this.onGoingReq.length>0
    },

    loadStation() {
      let $onNext = (response) => {
        this.station = response.data[0].name;
        Vue.$cookies.set('station',response.data[0]._id);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/opts/'+this.agent.stationID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData() {
      const agent = Vue.$cookies.get("user");
      console.log(agent.pid)
      let $onNext = (response) => {
        this.agent = response.data[0];
        Vue.$cookies.set('agent',response.data[0]);
        this.loadStation()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/agents/'+agent.pid,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {
    this.loadData();
    
  },
};
</script>



<style scoped>
 .contentAgent {
  padding: 70px 50px 0px 50px;
  min-height: calc(100vh - 70px);
 }
</style>
