<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
      

      <div class="editionPanel">
        <div class="row">

          <div class="col-md-6 pr-md-1">
            <base-input
                  :disabled="!__item.clientName"
                  placeholder="Nombre"
                  v-model="__item.nbrpts"
                  type = "num"
                  :range = "{min: 0,  max:  __item.currentSolde}"
                  :error = "errorCtrl"
                  ></base-input>
            </div>

            <div class="col-md-6 pr-md-1">
            <base-input
                  placeholder="Nombre"
                  :disabled="!__item.clientName"
                  v-model="__item.values"
                  type = "num"
                  :range = "{min: 0}"
                  :error = "errorCtrl"
                  ></base-input>
            </div>

          <div class="col-md-12 pr-md-1">
                <selector :_customData="clientListStructure" :_showTitle='false' :_parentItem="__item" _warpper="clientID" :disabled="__clientID !== ''" :_cb="setCurrentSolde"></selector>
            </div>

            


        </div>
        <div class="text-right">
          <div class="col-md-12" style="margin-top:5px;">
            
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },

    __clientID: {
      type: String,
      default: () => {
        return '';
      },
    },

    __catList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      clientListStructure: {},
      fullList: [],
      errorCtrl: [],
    }
  },
  methods: {

    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },

    setCurrentSolde(value) {
      if(value.length>0) {
        this.$props.__item.clientName = value[0].text
        this.$props.__item.currentSolde = value[0].extra
        this.$props.__item.clientID = value[0].value
      }
       
    },

    setClientList() {
      if(this.$props.__catList.length === 0) 
        return
      this.fullList = this.$props.__catList;
      this.clientListStructure = {}
      this.clientListStructure.editable = true;
      this.clientListStructure.grp = {
        type: 'DD',
        name: 'Client'
      }
      this.clientListStructure.list = this.$props.__catList.map(c => ({text: c.name, value: c.id, extra: c.solde }));
      if(this.__clientID !== '') {
        this.clientListStructure.list = this.clientListStructure.list.filter(e => e.value === this.__clientID)
        if(this.clientListStructure.list.length === 1) {
          this.setCurrentSolde(this.clientListStructure.list)
          this.clientListStructure.editable = false;
        }
      }
      },
  },
  mounted() {
    this.setClientList();
  },

};
</script>
<style>
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
