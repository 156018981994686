export default {
  colors: {
    default: "#CCC",
    primary: "#999",
    info: "#1d8cf8",
    danger: "#fd5d93",
    teal: "#00d6b4",
    primaryGradient: ['rgba(0, 0, 0, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)'],
  },
  paths: {
    apiDomain: '/api',
    cloudDomain: '',
    appPath: 'http://195.35.2.220:8080/',
    shopPath: 'http://195.35.2.220:8080/shop/',
  }
}
