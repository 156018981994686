<template>
  <div class="row">
    <h4>{{useTitle}}</h4>
    <div class="col-md-12">
      <List
        :__list="dataArray"
        :__byClient = "byClient !== ''"
        :__byArticle = "byArticle !== ''"
        :__byCars = "byCars !== ''"
        :__byAgent = "byAgent !== ''"
        :__byItem = "byItem !== ''"
        :__isOnLoad="isListOnload"
        ></List>
    </div>
  </div>
</template>
<script>
import List from './List';
export default {

  components: {
    List,
  },
  props: {
    useTitle:  {
      type: String,
      default: () => {
        return '';
      },
    },
    byClient: {
      type: String,
      default: () => {
        return '';
      },
    },
    
    byCars: {
      type: String,
      default: () => {
        return '';
      },
    },

    byAgent: {
      type: String,
      default: () => {
        return '';
      },
    },

    byArticle: {
      type: String,
      default: () => {
        return '';
      },
    },

    byItem: {
      type: String,
      default: () => {
        return '';
      },
    },
    
    
  },

  data() {
    return {
      moduleRoute: '/cmd',
      isListOnload: false,
      dataArray: [],
      onGoingReq:[],
      intervalID: null,
      timeoutVlue: 5000,
    };
  },

  computed: {
    listStyle() {
     
    },
  },

  methods: {
    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },


    loadData(id, container) {
      let $onNext = (response) => {
        this.dataArray = response.data
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      var _route = '/cmd';
      if(this.$props.byClient !== '') {
        _route+="/client/"+this.$props.byClient
      }
      if(this.$props.byCars !== '') {
        _route+="/cars/"+this.$props.byCars
      }

      if(this.$props.byAgent !== '') {
        _route+="/agent/"+this.$props.byAgent
      }

      if(this.$props.byArticle !== '') {
        _route+="/article/"+this.$props.byArticle
      }
      
      if(this.$props.byItem !== '') {
        _route+="/"+this.$props.byItem
      }

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: _route,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

  },

  mounted() {
    if(this.$props.byItem !=='') {
      this.timeoutVlue =  2000000;
    }
    if(this.$props.byClient !=='') {
      this.timeoutVlue =  20000;
    } else if(this.$props.byCars !=='') {
      this.timeoutVlue =  20000;
    } else if(this.$props.byAgent !=='') {
      this.timeoutVlue =  5000;
    } else if(this.$props.byArticle !=='') {
      this.timeoutVlue =  20000;
    } else {
      this.timeoutVlue =  5000;
    }
    
    this.intervalID = setInterval(() => {
        this.loadData();
    }, this.timeoutVlue);
    this.loadData();
  },

  destroyed() {
    console.debug('destroyed');clearInterval(this.intervalID)
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style></style>
