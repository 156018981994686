<template>
  <div class="demo_container">
    <div class="panel" :style="panelStyle">
        <div class="pageContainer" :style="pageContainerStyle">
          <div class="page" v-for="(page, indexPage) in jsonToDump.pages" :key="indexPage" :style="pageStyle[indexPage]">
            <div class="zone" v-for="(zone, indexZone) in jsonToDump.pages[indexPage].ZoneDisplaySet" :key="indexZone" :style="getZoneStyle(indexPage, indexZone)">
              <span v-if="zone.ZoneType == 0 "> {{ zone.Content }}</span>
              <img  v-if="zone.ZoneType == 1 " :src="getTof(zone.Content)" width="100%" height="100%"/>
              
              <span v-if="zone.ZoneType == 2 "> {{ df(zone.Content) }}</span>
              
              <span  v-if="zone.ZoneType == 3 "
                style="background-color: #005ef6;
                font-size: 0.9vw;
                text-align: center;
                font-weight: bold;
                border-radius: 5px;"
              >P - {{ jsonToDump.pages.length }}</span>
              
            </div>
        </div>
      </div>

    </div>
    <div class="info" :style="getInfoStyle()">
      Page en cours: {{ currentPage+1 }}  -  délai d'affichage: {{ counter }} secondes  -  Nombre total des pages: {{ jsonToDump.pages.length }}
        <b-icon @click="jump(-1)" icon="arrow-left-circle-fill"></b-icon>  <b-icon icon="arrow-right-circle-fill" @click="jump(1)"></b-icon>
    </div>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  name: 'demo',
  components: {

  },
  props: {
    Afficheur: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Actif: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    jsonToDump: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      intervalCheck:null,
      intervalCounter:null,
      panelStyle: '',
      panelW:0,
      panelH:0,
      currentPage:0,
      pageStyle: Array(),
      pageContainerStyle: '',
      counter:0,
      now: new Date(),
    };
  },

  computed: {
    
  },

  methods: {
    getTof(c) {
      return './uploads/'+c.split("/uploads/")[1];
    },
    df(id){
      const ret = this.Afficheur.supportedCodes.filter(e=> e.value === id);
      if(ret.length > 0) {
        if(ret[0].ihm[0] === "#")
          return ret[0].ihm.substring(1);
        return dateFormat(this.now, ret[0].ihm)
      }
      return '';
    },
    jump(sens) {
      this.currentPage += sens
      if(this.currentPage == -1) {
        this.currentPage = this.jsonToDump.pages.length-1;
      }
      if(this.currentPage == this.jsonToDump.pages.length){
          this.currentPage = 0;
      }
      this.startPooling(this.currentPage)
    },
    startPooling(index) {
      this.currentPage = index;
      this.pageContainerStyle = 'margin-top:'+-(index * this.panelH)+'px;';
      clearInterval(this.intervalCounter);
      clearTimeout(this.intervalCheck)
      this.counter = this.jsonToDump.pages[index].PageStayTime / 100;
      this.intervalCounter = setInterval(() => {
        this.counter --;
      },1000)
      this.intervalCheck = setTimeout(()=> {
        this.currentPage ++;
        if(this.currentPage == this.jsonToDump.pages.length){
          this.currentPage = 0;
        }
        this.startPooling( this.currentPage)
      }, this.jsonToDump.pages[index].PageStayTime * 10)
    },
    getInfoStyle() {
      return "text-align: center ; margin-top: "+(this.Afficheur.height +10)+"px;";
    },
    getPanelStyle() {
      let ret ='';
      if(this.$el.clientWidth > this.Afficheur.width) {
        this.panelW = this.Afficheur.width;
        this.panelH = this.Afficheur.height;
        ret += 'width: '+this.Afficheur.width+'px;';
        ret += 'height: '+this.Afficheur.height+'px;';
        ret += 'margin-left: '+((this.$el.clientWidth - this.Afficheur.width) /2)+'px;';
        //ret += 'margin-top: '+((this.$el.clientHeight - this.Afficheur.height) /2)+'px;';
        for(var i =0; i<this.jsonToDump.pages.length; i++) {
          this.pageStyle[i] = ret+ 'margin-left:0px; background-color:rgb('+this.jsonToDump.pages[i].BGColor_R+','+this.jsonToDump.pages[i].BGColor_G+','+this.jsonToDump.pages[i].BGColor_B+');';
        }
      } else {

      }
      this.panelStyle = ret;
    },
    getZoneStyle(indexPage, indexZone) {
      let ret ='';
      let page = this.jsonToDump.pages[indexPage];

      let zoneParams = page.ZoneSet[indexZone];
      let zoneData = page.ZoneDisplaySet[indexZone];
      ret += 'position: absolute;';
      ret+= 'z-index:'+indexZone+';';
      ret += 'width: '+zoneParams.ZoneWidth+'px;';
      ret += 'height: '+zoneParams.ZoneHeight+'px;';
      //if(indexZone === 0)
        ret += 'margin-left: '+ zoneParams.XPos+'px;';
      ret += 'margin-top: '+zoneParams.YPos+'px;';
      ret += 'background-color:rgb('+zoneData.BGColor_R+','+zoneData.BGColor_G+','+zoneData.BGColor_B+');';
      ret += 'color:rgb('+zoneData.FGColor_R+','+zoneData.FGColor_G+','+zoneData.FGColor_B+');';
      ret += 'text-align: '+this.getAlign(zoneData.Align);
      ret += 'font-size: '+this.getFonts(zoneData.FontStyle)+'px;';
      ret += 'padding-top: '+this.getVAlign(zoneData.Align,this.getFonts(zoneData.FontStyle),zoneData.ZoneType,zoneParams.ZoneHeight);
      return ret;
    },
    getVAlign(a,txtHeight,type,centainerHeight){
      if(type === 1)
        return '0px';
      var typeMarginB = (type === 0)? 10 : 25;
      var typeMarginM = (type === 0)? 1 : 5;
      if([34,33,32].includes(a))
        return centainerHeight-txtHeight-typeMarginB+'px';
      if([1,0,2].includes(a))
      return (centainerHeight/2)-txtHeight-typeMarginM+'px';
      return 'Opx';
    },
    getAlign(a){
      if([34,2,18].includes(a))
        return 'right;';
      if([32,0,16].includes(a))
        return 'center;';
      return 'left;';
    },
    getFonts(id){
      const ret = this.Afficheur.supportedFonts.filter(e=> e.value === id);
      if(ret.length > 0) {
        return ret[0].ihm;
      }
      return 11;
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.getPanelStyle();
      });
    },
    onMount() {
      for(var i =0; i<this.jsonToDump.pages.length; i++) {
      this.pageStyle.push('')
      }
      this.getPanelStyle()
      this.onScreenResize()
      this.startPooling(0)
    }
  },
  mounted() {
    this.onMount()
  },
  unmounted(){
    clearInterval(this.intervalCheck)
  },
  watch: {
    jsonToDump: function () {
      clearInterval(this.intervalCheck);
      this.onMount()
    },
  },
};
</script>
<style>

.demo_container
{
  width: 100%;
  padding: 1.5%;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #7474b0;
}
.demo_container .panel
{
  background-color: #000000;
  border: dotted 1px #fff;
  overflow: hidden;
  position: absolute;
}
.demo_container .panel .pageContainer {
  transition: 0.9s margin-top ease;
}
.demo_container .panel .pageContainer .page
{
  background-color: #000000;
}
.demo_container .panel .pageContainer .page .zone
{
  float: left;
}

.info{
  font-size: 11px;
  font-weight: bold;
  color: black;
  text-align: center;
}

</style>
