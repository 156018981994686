<template>
  <b-overlay
    :show="isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <div v-if="!isOnLoad" class="defaultStyle" :style="_customCss">
      <ico-btn style="" type="simple" icone="plus-circle" class="plus_pram" :cb="()=>{addAction()}"></ico-btn>
      <div class="title">Gestion de la template</div>
      
      <div class="row edition" v-if="onEdition !== null">
        
        <div class="col-md-2">
          <base-input
          placeholder="Titre"
          v-model="onEdition.flagName"
          type = "string"
          :error = "errorCtrl"
          ></base-input>
        </div>

        <div class="col-md-1">
          <base-input
          placeholder="ID"
          v-model="onEdition.flagID"
          type = "string"
          :error = "errorCtrl"
          ></base-input>
        </div>


        <div class="col-md-2">
          <base-input
          placeholder="Valeur"
          v-model="onEdition.flagValue"
          type = "string"
          :error = "errorCtrl"
          ></base-input>
        </div>


        <div class="col-md-2">
          <b-form-select
            v-model="onEdition.flagType"
            :options="typeList"
            class="form-control"
          ></b-form-select>
        </div>

        <div class="col-md-2">
          <b-form-select
            :disabled="onEdition.flagType !== 'SELECTOR'"
            v-model="onEdition.flagConf"
            :options="selectorList"
            class="form-control"
          ></b-form-select>
        </div>

        <div class="col-md-1">
          <b-form-select
            v-model="onEdition.flagVisible"
            :options="[{text: 'Oui', value: true}, {text: 'Non', value: false}]"
            class="form-control"
          ></b-form-select>
        </div>

        <div class="col-md-2">
          <b-button size="sm" variant="success" @click="save()">OK</b-button>
        </div>
      
      </div>
        
        <div class="row item" v-for="(itm, index) in tpls" >
          <div class="col-md-2"> {{ itm.flagName }}</div>
          <div class="col-md-2"> {{ itm.flagID }}</div>
          <div class="col-md-1"> {{ itm.flagValue }}</div>
          <div class="col-md-1"> <b-icon :icon="getTypeIcon(itm.flagType)" aria-hidden="true" style="color: green"></b-icon></div>
          <div class="col-md-3"> {{ getConfig(itm.flagConf) }}</div>
          <div class="col-md-1"><b-icon :icon="(itm.flagVisible === true ) ? 'check-square-fill' : 'check-square'" aria-hidden="true" style="color: blue"></b-icon></div>
          <div class="col-md-2"><b-icon
            icon="gear-fill"
            aria-hidden="true"
            @click="edition(index)"
          ></b-icon>
          <b-icon
            icon="x-circle"
            aria-hidden="true"
            @click="delet(itm.flagID)"
          ></b-icon>

          </div>
        </div>


     

    </div>
  </b-overlay>
</template>
<script>
import { computed } from 'vue';

  export default {
    name: 'templateManager',
    components: {

    },
    props: {
      _customData: {
        type: Array,
        default: () => [],
      },

      _customCss: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        errorCtrl: [],
        isOnLoad: false,
        tpls: [],
        onEdition: null,
        selectorList: [],
        typeList: [
          {text: 'Liste', value: 'SELECTOR'},  {text: 'Champs nombre', value: 'IMP_NUM'},  {text: 'Champs texte', value: 'IMP_STR'},  {text: 'Champs date', value: 'IMP_DAT'}
        ],
        list: [],
        state: {
          flagName: '',
          flagID: '',
          flagValue: '',
          flagType: '',
          flagConf: '',
          flagVisible: false,
        }

      }
    },

    methods: {
      getTypeIcon(type) {
        switch (type) {
          case 'IMP_NUM':
            return 'sort-numeric-up'
          case 'IMP_STR':
            return 'textarea-t'
          case 'IMP_DAT':
            return 'calendar-date-fill'
          case 'SELECTOR':
            return 'list-check'
        }
      },

      getConfig(c) {
        if(!c)
          return '';
        var ret = this.selectorList.filter(e => e.value === c)
        if(ret.length>0) {
          return ret[0].text;
        }
        return ''
      },

      save() {
        this.onEdition = null;
        this.$emit("updateTpl", {
          data: this.tpls
        });
      },

      addAction() {
        this.tpls.push({...this.state})
        this.onEdition = this.tpls[this.tpls.length -1]
      },

      edition(idx) {
        this.onEdition = this.tpls[idx]
      },

      delet(id) {
        this.tpls = this.tpls.filter(e => e.flagID !== id)
        this.$emit("updateTpl", {
          data: this.tpls
        });
      },

      loadData() {
        let $onNext = (response) => {
          this.selectorList = response.data.map(e => ({text: e.name, value: e._id}));
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/grp_opts/type/SELECT_TPL',
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },
     },

    mounted() {
      this.tpls = this.$props._customData
      this.loadData();
    },

    computed: {
      // varPath: function() {
      // return this.$props.parentItem[this.$props._model];
      // },
    },

    watch: {  
    },
  }
</script>
<style scoped>
.defaultStyle {
  width: 100%;
  margin-bottom:5px;
}
.defaultStyle .title {
  width: 100% !important;
    margin-bottom: 18px;
    font-size: 14px;
    font-weight: bolder !important;
}
.edition {
  border-radius:10px;
  background-color:#1a1e34;
  padding-top: 10px;
}
.item {
    border-bottom: solid 1px #1a1e34;
    font-size: 12px;
    padding: 10px 0;
}
</style>
