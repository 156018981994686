const FormData = require('form-data');
import axios from 'axios'
import config from '../config';

var useCustomServer = '';

export function getAppPath() {
  return config.paths.appPath
}

export function getAppShopPath() {
  return config.paths.shopPath
}

export function httpAction(payload) {
  useCustomServer = '';
  if(payload.server) {
    useCustomServer = payload.server;
  }
  switch (payload.methode) {
    case 'C_POST':
      custompostAction(payload);
      break;
    case 'POST':
      postAction(payload);
      break;
    case 'PUT':
      putAction(payload);
      break;
    case 'DELETE':
      deleteAction(payload);
      break;
    case 'GET':
      getAction(payload);
      break;
  }
}

function custompostAction(payload) {
  axios
    .post(getDomaine() + payload.route,
    payload.data,//convertData(payload.data), 
    {
      headers: {'Content-Type': 'text/plain'},
      responseType: 'text'
    })
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function postAction(payload) {
  axios
    .post(getDomaine() + payload.route,
    payload.data,//convertData(payload.data), 
     getHeader())
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function putAction(payload) {
  const options = getHeader();
  options.params = payload.data?? {};// convertData(payload.data?? {});
  axios
    .put(getDomaine() + payload.route, {}, options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function deleteAction(payload) {
  axios
    .delete(getDomaine() + payload.route,
    payload.data,//convertData({...payload.data, useMethode: 'DELETE'}),
    getHeader())
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function getAction(payload) {
  const options = getHeader();
  options.params = payload.data?? {};// convertData(payload.data?? {});
  axios
    .get(getDomaine() + payload.route, options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function getHeader() {
  return {
    headers: {
      
    },
  };
}
function getDomaine(type = 'api') {
  if(useCustomServer !== '') {
    return useCustomServer;
  }
  switch(type) {
    case 'api':
      return config.paths.apiDomain;
    case 'cloud':
      return config.paths.cloudDomain;
  }

}

function convertData(payload) {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if(key === "useMethode") {
      formData.append(key, payload[key]);
    } else {
      formData.append('_flg__'+key, payload[key]);
    }
    
  });
  return formData;
}

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
}

export function loadFolderContent(payload) {
  const options = {};//getHeader();
  //options.params = {'____': payload.folder}?? {};// convertData(payload.data?? {});
  axios
    .get('/api/folders/files/'+payload.folder, options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

export function uploadFolderContent(payload) {
  axios
    .post(getDomaine('cloud'), payload.data, {})
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

export function deleteFolderContent(payload) {
  axios
    .post(payload.url, payload.data, {})
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}
