<template>
  <div class="row">
    <div class="col-md-12" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__clientID="clientID"
        :__prdList = "prdList"
      ></Edit>
    </div>
    <h4>{{useTitle}}</h4>
    <ico-btn class="btn-plus-pos" style="position: absolute; top: 15px; right:1%; z-index:1234" type="simple" icone="plus-circle" :cb="()=>{addAction()}"></ico-btn>
    <div :class="listStyle" v-if="this.onEditionId === null">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
        :__showFilter="!this.$props.embeded"
        :__prdList = "prdList"
        :__modeClient = "modeClient"
        ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    modeAdmin:  {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    clientID:  {
      type: String,
      default: () => {
        return '';
      },
    },

    embeded:  {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    modeClient:  {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    useTitle:  {
      type: String,
      default: () => {
        return '';
      },
    },
  },

  data() {
    return {
      moduleRoute: '/cars',
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      dataArray: [],
      onGoingReq:[],
      prdList: []
    };
  },

  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-12' : 'col-md-12';
    },
  },

  methods: {
    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    console.log("CANCEL ", this.onEditionId)
    },

    saveAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].isActive = val;
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'isActive']);
      data.isActive = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    editAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx)
      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    addAction() {
      var clientID =''
      if(this.$props.clientID) {
        clientID = this.$props.clientID
      }
      
      if(clientID === '') {
        this.$bvModal
        .msgBoxConfirm("Veuillez séléctionner un client", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        return
      }

      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data, () =>
          this.editAction(response.data)
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {parentID: clientID},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray.push(response.data[0]);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute+ (id != null ? '/'+id : (this.$props.clientID ? '/list/'+this.$props.clientID: '/')),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadProds() {
        let $onNext = (response) => {
          this.prdList = response.data.filter(e => e.catID === '665ba890bc69edc5d42c031a' || e.subcatID === '665e782789645eb5bd7e1391');
          this.loadData();
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/prods',
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },

  },
  mounted() {
    this.loadProds();
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style></style>
