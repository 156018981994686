<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link
          to="/options"
          name="Options"
          icon="tim-icons icon-attach-87"
        />

        <sidebar-link
          to="/clients"
          name="Clients"
          icon="tim-icons icon-badge"
        />

        <sidebar-link
          to="/cmds"
          name="Achats"
          icon="tim-icons icon-chart-pie-36"
        />

        <sidebar-link
          to="/bons"
          name="Vouchers"
          icon="tim-icons icon-chart-pie-36"
        />

        <sidebar-link
          to="/agents"
          name="Agents"
          icon="tim-icons icon-single-02"
        />

        <sidebar-link
          to="/cars"
          name="Parc"
          icon="tim-icons icon-bus-front-12"
        />

        <sidebar-link
          to="/categorys"
          name="Catégories"
          icon="tim-icons icon-components"
        />

        <sidebar-link
          to="/products"
          name="Produits"
          icon="tim-icons icon-chart-pie-36"
        />
      </template>

      
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./SampleNavbar.vue";
import ContentFooter from "./SampleFooter.vue";
import DashboardContent from "./Content.vue";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
